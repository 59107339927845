/* .dragDropField {
  display: flex;
  flex-direction: row;
  gap: 1em;
} */

.addedFiles {
  text-align: center;
  align-items: center;
  padding: 20px;
  margin: 20px auto;
  background-color: rgb(255, 255, 255);
  max-width: 600px;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;

  padding: 20px;
  margin: 20px auto;
  border: 4px dashed gray;
}

.rejectedFiles {
  text-align: center;
  align-items: center;
  padding: 20px;
  margin: 20px auto;
  background-color: darkred;
  max-width: 600px;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
}

.dropzone {
  background-color: rgb(255 255 255 / 40%);
  max-width: 600px;
  min-height: 200px;

  padding: 20px;
  margin: 20px auto;
  border: 1px dashed rgb(163, 163, 163);
  border-radius: 6px;

  display: flex;
  align-items: flex-start;
  justify-content: left;

  /* three lines */
  position: relative;
  /*top: 50%;
  transform: translateY(-50%); */
}

.centered {
  position: absolute;
  top: 43%;
  left: 48%;
  transform: translate(-25%, -25%);
  transform: scale(4, 4);
  opacity: 7.5%;
  border: 2px solid;
  padding: 2px;
  border-radius: 6px;
  transform-origin: center center;
}
