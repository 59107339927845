.App {
  text-align: center;
  position: relative;
  min-height: 100vh;
}

.App::before {
  background-size: cover;
  background-repeat: no-repeat;
  background-position: center;
  opacity: 0.3;
  position: absolute;
  content: "";
  width: 100%;
  height: 100%;
  left: 0;
  z-index: -1;
}

.App-Filesplitter::before {
  background-image: url("./assets/background1.jpg");
}

.App-Appendix::before {
  background-image: url("./assets/background2.png");
}

.App-logo {
  height: 40vmin;
  pointer-events: none;
}

@media (prefers-reduced-motion: no-preference) {
  .App-logo {
    animation: App-logo-spin infinite 20s linear;
  }
}

.App-header {
  background-color: #282c34;
  min-height: 100vh;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  font-size: calc(10px + 2vmin);
  color: white;
}

.App-link {
  color: #61dafb;
}

@keyframes App-logo-spin {
  from {
    transform: rotate(0deg);
  }
  to {
    transform: rotate(360deg);
  }
}

.Interface-button {
  margin: 20px;
}

.Menu-item {
  color: #1a1a1a;
  text-decoration: none;
  text-decoration-thickness: 0;
}

.logout-div {
  position: absolute;
  right: 15px;
  display: grid;
}

.logout {
  color: #61dafb;
  text-decoration: underline;
  cursor: pointer;
  padding: 5px 0;

  &:hover {
    color: #6d778d;
  }
}
