@import "../../assets/styles/variables";

.acoButton {
  font-family: $base-font-family;
  padding: 6px 24px;
  background: none;
  border-radius: 24px;
  border-style: solid;
  border-width: 1px;
  color: inherit;
  cursor: pointer;
  display: inline-flex;
  font-size: 14px;
  line-height: 1.5;
  position: relative;
  text-align: center;
  text-decoration: none;
  transition: color 0.2s ease-in-out;

  &.primary {
    background-color: $secondary;
    border-color: $secondary;
    color: $white;

    &:hover {
      background-color: $primary;
      border-color: $primary;
    }
  }
  &.secondary {
    background-color: $white;
    border-color: $secondary;
    color: $secondary;

    &:hover {
      background-color: $secondary;
      color: $white;
    }
  }
  &.disabled {
    @include readonly();
    pointer-events: none;
    color: #aaa;
  }
}
