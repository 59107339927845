body {
  background-color: rgb(255, 255, 255);
}

.loading {
  margin: 20px auto;
}

.forbiddenAppBody {
  padding: 100px;
  opacity: 60%;
}

.forbiddenAppLogo {
  transform: translate(-25%, -25%);
  transform: scale(4, 4);
  margin: 20px;
  opacity: 100%;
}
