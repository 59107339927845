// Colors
$white:     #ffffff;
$primary:   #d89f5a;
$secondary: #7d8f80;
$tertiary:  #202e42;
$text:      #172b4d;
$success:   #3f7d20;
$error:     #f6511d;
$warning:   #ffb400;
$toDo:      #BEBEBE;
$inProgress:#7494ea;

// Filter colors - for SVG styling
$filter-primary:    invert(61%) sepia(56%) saturate(391%) hue-rotate(353deg) brightness(99%) contrast(88%);
$filter-secondary:  invert(59%) sepia(8%) saturate(573%) hue-rotate(78deg) brightness(92%) contrast(84%);
$filter-text:       invert(14%) sepia(13%) saturate(5832%) hue-rotate(193deg) brightness(85%) contrast(91%);

$success-background: rgba(97, 191, 173, 0.06);
$error-background:   rgba(231, 81, 82, 0.06);
$warning-background: rgba(255, 160, 15, 0.06);

// Font family
$base-font-family:  'MuseoSans', sans-serif !default;
$serif-font-family: 'Museo', sans-serif !default;

// Font size
$base-font-size: 16px;

// Font weight
$light-font:    300;
$regular-font:  500;
$bold-font:     700;

// Layout
$basic-padding: 24px;
$wider-padding: 30px;

@mixin readonly() {
    background-color: hsl(0, 0%, 95%);
    border-color: hsl(0, 0%, 90%);
    cursor: default;
}