@import "../../assets/styles/variables";

.LoginWrapper {
  height: 100vh;
  display: flex;
  justify-content: center;
  align-items: center;

  h1 {
    color: $primary;
  }
}

.LoginContent {
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;
  text-align: center;
  gap: 12px;
}
